<template>
    <gmap-map class="gmap" ref="gmap" map-type-id="hybrid" :center="center" :zoom="zoom">
        <gmap-marker :key="index"
                     v-for="(m, index) in locationMarkers" 
                     :position="m.position" 
                     :icon="(m.gate.status) ? getIcon(m.gate.status) : getIcon(0)"
                     :title="m.gate.name"
                     @click="markerClicked(m.gate.id)"></gmap-marker>
    </gmap-map>
</template>

<style scoped>
    .gmap {
        width: 100%;
        height: 100%;
    }
</style>

<script>
    export default {
        props: {
            gates: [],
            maxZoom: {
                default: 17,
                type: Number
            },
            minZoom: {
                default: 7,
                type: Number
            },
        },
        data() {
            return {
                locationMarkers: [],
                center: {
                    lat: 57.189531,
                    lng: 14.041255
                },
                zoom: 10,
            }
        },
        methods: {
            markerClicked(id) {
                this.$emit('marker-clicked', id);
            },
            addLocationMarkers() {
                this.locationMarkers = [];
                this.$refs.gmap.$mapPromise.then((map) => {
                    const bounds = new google.maps.LatLngBounds(null);
                    for (let i in this.gates) {
                        if (this.gates[i].deviceLat && this.gates[i].deviceLon) {
                            const marker = {
                                lat: this.gates[i].deviceLat,
                                lng: this.gates[i].deviceLon,
                            }
                            this.locationMarkers.push({
                                position: marker,
                                gate: this.gates[i],
                            });
                            bounds.extend(marker);
                        }
                    }
                   
                    if (this.locationMarkers.length) {
                        map.fitBounds(bounds);
                        const self = this;
                        var listener = google.maps.event.addListener(map, "idle", function () { // wait for fitBounds to finish
                            if (map.getZoom() > self.maxZoom) map.setZoom(self.maxZoom);
                            if (map.getZoom() < self.minZoom) map.setZoom(self.minZoom);
                            google.maps.event.removeListener(listener);
                        });
                    } else this.locateGeoLocation();
                });
            },
            locateGeoLocation() {
                navigator.geolocation.getCurrentPosition(res => {
                    this.center = {
                        lat: res.coords.latitude,
                        lng: res.coords.longitude
                    };
                });
            },
            getIcon(state) {
                let icn = {
                    0: { // unknown
                        path: 'M 0 0 L 2 -3 L 5 -3 L 5 -9 L -5 -9 L -5 -3 L -2 -3 L 0 0',
                        fillColor: '#ccc',
                        fillOpacity: 1,
                        strokeColor: '#000',
                        strokeWeight: 1,
                        scale: 2,
                    },      
                    10: { // closed
                        path: 'M 0 0 L 2 -3 L 5 -3 L 5 -9 L -5 -9 L -5 -3 L -2 -3 L 0 0',
                        fillColor: '#F00',
                        fillOpacity: 1,
                        strokeColor: '#000',
                        strokeWeight: 1,
                        scale: 2,
                    },     
                    20: { // open
                        path: 'M 0 0 L 2 -3 L 5 -3 L 5 -9 L -5 -9 L -5 -3 L -2 -3 L 0 0',
                        fillColor: '#0F0',
                        fillOpacity: 1,
                        strokeColor: '#000',
                        strokeWeight: 1,
                        scale: 2,
                    },     
                    30: { // opening
                        path: 'M 0 0 L 2 -3 L 5 -3 L 5 -9 L -5 -9 L -5 -3 L -2 -3 L 0 0',
                        fillColor: '#0F0',
                        fillOpacity: .5,
                        strokeColor: '#000',
                        strokeWeight: 1,
                        scale: 2,
                    },     
                    40: { // closing
                        path: 'M 0 0 L 2 -3 L 5 -3 L 5 -9 L -5 -9 L -5 -3 L -2 -3 L 0 0',
                        fillColor: '#F00',
                        fillOpacity: .5,
                        strokeColor: '#000',
                        strokeWeight: 1,
                        scale: 2,
                    },     
                }
                return icn[state];
            }
        },
        computed: {
            
        },
        watch: {
            gates: {
                handler() {
                    this.addLocationMarkers();
                },
            }
        },
        mounted() {
            this.addLocationMarkers();
            //this.locateGeoLocation();
        },
    }
</script>