<template>
    <div v-if="stateUser.userRole >= userRoles.DemexAdmin" class="dmx-gate-card">
        <b-row>
            <b-col cols="12" class="d-flex justify-content-between pt-2">
                <div v-if="isAddCustomer === false">
                    <h5>{{ gateId > 0 ? 'Continue new gate' : 'Add new gate' }}
                    </h5>
                    <small v-if="gate.stageId === 1" class="text-primary"><b>Basic information</b> / <em>Device</em></small>
                    <small v-if="gate.stageId === 2" class="text-primary"><em>Basic information</em> / <b>Device</b></small>
                </div>
                <div v-if="isAddCustomer === true">
                    <h5>Add new customer</h5>
                </div>
                <div v-if="isAddCustomer === false">
                    <p class="close-btn-addnewgate" @click="closeModal">x</p>
                </div>
            </b-col>
        </b-row>
        <ValidationObserver ref="observer" tag="form" v-slot="{ handleSubmit }" @submit.prevent>
            <b-row>
                <b-col cols="12" v-if="isAddCustomer === false">
                    <div v-if="gate.stageId === 1">
                        <b-row no-gutters align-v="center">
                            <b-col sm="12">
                                <label class="mb-1">Gatename</label><small class="text-danger"><em> *</em></small>
                                <ValidationProvider name="Gatename" rules="required" v-slot="v">
                                    <b-form-input v-model="gate.systemName" spellcheck="false" type="text"
                                        autocomplete="off" v-on:keyup="systemNameEventHandler($event)"></b-form-input>
                                    <small class="text-danger"><em>{{ v.errors[0] }}</em></small>
                                </ValidationProvider>
                                <small class="text-danger" v-if="errorMessage.length > 0"><em>{{ errorMessage
                                }}</em></small>
                            </b-col>
                        </b-row>
                        <b-row no-gutters align-v="center" class="mt-2">
                            <b-col sm="12">
                                <label class="mb-1">Ordernumber</label><small class="text-danger"><em> *</em></small>
                                <ValidationProvider name="Ordernumber" rules="required" v-slot="v">
                                    <b-form-input spellcheck="false" type="text" autocomplete="off"
                                        v-model="gate.orderNumber"></b-form-input>
                                    <small class="text-danger"><em>{{ v.errors[0] }}</em></small>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                        <b-row no-gutters align-v="center" class="mt-2">
                            <b-col sm="12">
                                <label class="mb-1">Owner comp.</label><small class="text-danger"><em> *</em></small>
                                <ValidationProvider name="Owner comp." rules="required_dd" v-slot="v">
                                    <b-input-group>
                                        <b-select v-model="gate.ownerCustomerId"
                                            :options="customerOwnerListFormatted"></b-select>
                                        <b-input-group-append>
                                            <b-button variant="success" @click="switchToAddCustomer()">
                                                <b-icon icon="plus"></b-icon>
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger"><em>{{ v.errors[0] }}</em></small>
                                </ValidationProvider>
                                <span v-if="customerEmail !== ''">
                                    <label class="mb-1 mt-2">Owner E-mail</label>
                                    <b-form-input spellcheck="false" autocomplete="off" type="email" disabled
                                        v-model="customerEmail"></b-form-input>
                                </span>
                            </b-col>
                        </b-row>
                        <b-row no-gutters align-v="center" class="mt-2">
                            <b-col sm="12">
                                <label class="mb-1">Country</label><small class="text-danger"><em> *</em></small>
                                <ValidationProvider name="Country" rules="required_dd" v-slot="v">
                                    <b-select v-model="gate.countryId" :options="countryListFormatted"></b-select>
                                    <small class="text-danger"><em>{{ v.errors[0] }}</em></small>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                        <b-row no-gutters align-v="center" class="mt-2">
                            <b-col sm="12">
                                <label class="mb-1">Timezone</label><small class="text-danger"><em> *</em></small>
                                <ValidationProvider name="Timezone" rules="required" v-slot="v">
                                    <b-select v-model="gate.timeZoneId" :options="timeZoneListFormatted"></b-select>
                                    <small class="text-danger"><em>{{ v.errors[0] }}</em></small>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                    </div>
                    <div v-if="gate.stageId === 2">
                        <b-row no-gutters align-v="center" class="mt-2">
                            <b-col sm="12">
                                <label class="mb-1">Device</label><small class="text-danger"><em> *</em></small>
                                <multiselect v-model="chosenDeviceObject" :options="deviceListFormatted"
                                    placeholder="Choose device" label="text" track-by="value"></multiselect>
                                <small class="text-danger" v-if="errorMessageDevice.length > 0"><em>{{ errorMessageDevice
                                }}</em></small>
                            </b-col>
                        </b-row>
                        <b-row no-gutters align-v="center" class="mt-2">
                            <label class="mb-1">Model</label>
                            <b-col sm="12">
                                <b-select :options="gateIOListFormatted" v-model="selectedGateIO"
                                    @change="onChangeGateModel"></b-select>
                            </b-col>
                        </b-row>
                        <b-row no-gutters align-v="center" class="mt-2">
                            <label class="mb-1">Type</label>
                            <b-col sm="12">
                                <b-select :options="gateTypeList" v-model="gateIO.type"></b-select>
                            </b-col>
                        </b-row>
                        <b-row no-gutters align-v="center" class="mt-2">
                            <label class="mb-1">Description</label>
                            <b-col sm="12">
                                <b-form-textarea v-model="gate.gateDescription" spellcheck="false"
                                    autocomplete="off"></b-form-textarea>
                            </b-col>
                        </b-row>
                    </div>
                    <b-row class="mt-2">
                        <b-col cols="12">
                            <div class="float-left">
                                <b-button v-if="gate.stageId !== 1" variant="info" type="submit"
                                    @click="handleSubmit(previousIndex())">
                                    {{ $t('common_previous') }}
                                </b-button>
                            </div>
                            <div class="float-right d-flex">
                                <b-button v-if="gate.stageId === 2" class="mr-1" variant="warning" type="submit"
                                    @click="handleSkip(gate.id)">
                                    {{ $t('common_skip') }}
                                </b-button>
                                <b-button class="mr-1" variant="success" type="submit"
                                    @click="handleSubmit(saveCloseGate())">
                                    {{ $t('common_save') }}
                                </b-button>
                                <b-button v-if="gate.stageId !== 2" variant="info" type="submit"
                                    @click="handleSubmit(nextIndex())">
                                    {{ $t('common_next') }}
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="12" v-if="isAddCustomer === true">
                    <customer-addnew @closeaddcustomer="switchToAddCustomer"
                        v-on:reloadcustomerlist="updateCustomerListAndSelect"></customer-addnew>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>

<!--table styles-->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
/* BOOTSTRAP unscoped */
.dmx-gate-card {
    background-color: #f3f2e8;
    border-radius: 4px;
    padding: 12px;
}
</style>

<style scoped>
.dialog-top {
    margin-top: 10vh;
}

.close-btn-addnewgate {
    font-weight: bolder;
    cursor: pointer;
}
</style>

<script>
import { mapState } from 'vuex';
import gateService from '@/services/gateService';
import gateAddnewComponent from '@/components/gates/gate-addnew.vue';
import { UserRoles } from '@/variables/variables';
import { Permissions } from '@/variables/permissionVariables';
import Multiselect from 'vue-multiselect';
import router from '@/router';
import customerAddNewComponent from '@/components/customers/customer-addnew.vue';

export default {
    components: { Multiselect, 'customer-addnew': customerAddNewComponent },
    props: {
        gateId: 0
    },
    data: () => ({
        gate: {
            id: 0,
            name: "",
            orderNumber: "",
            systemName: "",
            ownerCustomerId: 0,
            stageId: 1,
            countryId: 1,
            deviceId: 0,
            timeZoneId: "W. Europe Standard Time",
        },
        gateIO: {
            type: 0,
            outputSignals: [],
            inputSignals: []
        },
        chosenDeviceObject: {},
        deviceList: [],
        deviceListFormatted: [],
        customerOwnerList: [],
        customerOwnerListFormatted: [],
        gateInstallerList: [],
        systemNameList: [],
        countryList: [],
        countryListFormatted: [],
        timeZoneList: [],
        timeZoneListFormatted: [],
        gateIOList: [],
        gateIOListFormatted: [],
        gateTypeList: [],
        userRoles: UserRoles,
        permissions: Permissions,
        errorMessage: "",
        errorMessageDevice: "",
        selectedValue: null,
        customerEmail: "",
        ownerSelectedId: 0,
        selectedGateIO: -1,
        isAddCustomer: false
    }),
    computed: {
        ...mapState('authentication', {
            stateUser: 'user',
        }),
        compselected() {
            return this.gate.ownerCustomerId;
        }
    },
    watch: {
        compselected(newValue) {
            if (newValue > 0) {
                const ownObj = this.customerOwnerList.find(x => x.id === newValue);
                if (ownObj.email !== "")
                    this.customerEmail = ownObj.email;
                else
                    this.customerEmail = "No email listed!";
            } else {
                this.customerEmail = "";
            }
        },
        chosenDeviceObject: {
            handler() {
                if (this.chosenDeviceObject !== null && this.chosenDeviceObject.value > 0) {
                    this.errorMessageDevice = "";
                    this.gate.deviceId = this.chosenDeviceObject.value;
                } else {
                    this.gate.deviceId = 0;
                }
            }
        },
        deviceList: {
            handler() {
                this.chosenDeviceObject = { value: 0, text: 'Choose device' };
                for (let i in this.deviceList) {
                    this.deviceListFormatted.push({ value: this.deviceList[i].id, text: this.deviceList[i].name });
                }
            },
            immediate: true
        },
        customerOwnerList: {
            handler() {
                this.customerOwnerListFormatted = [{ value: 0, text: 'Choose company' }];
                for (let i in this.customerOwnerList) {
                    this.customerOwnerListFormatted.push({ value: this.customerOwnerList[i].id, text: this.customerOwnerList[i].name });
                }
            },
            immediate: true
        },
        timeZoneList: {
            handler() {
                this.timeZoneListFormatted = [{ value: 0, text: 'Choose timezone' }];
                for (let i in this.timeZoneList) {
                    this.timeZoneListFormatted.push({ value: this.timeZoneList[i].id, text: this.timeZoneList[i].name });
                }
            },
            immediate: true
        },
        countryList: {
            handler() {
                this.countryListFormatted = [{ value: 0, text: 'Choose country' }];
                for (let i in this.countryList) {
                    this.countryListFormatted.push({ value: this.countryList[i].id, text: this.countryList[i].name });
                }
            },
            immediate: true
        },
        gateIOList: {
            handler() {
                this.gateIOListFormatted = [{ value: -1, text: 'Choose gate model' }];
                for (let i in this.gateIOList) {
                    this.gateIOListFormatted.push({ value: i, text: this.gateIOList[i].name });
                }
            },
            immediate: true,
        }
    },
    methods: {
        saveCloseGate() {
            this.$refs.observer.validate().then(success => {
                if (!success) {
                    return;
                } else {
                    if (this.gate.id > 0) {
                        if (this.gate.deviceId === 0 && this.gate.stageId === 2) {
                            this.errorMessageDevice = "You have to choose a device in the list!";
                        } else {
                            this.updateGate()
                            this.closeModal();
                        }
                    }
                    else {
                        if (this.errorMessage === "") {
                            this.saveGate();
                            this.closeModal();
                        }
                    }
                }
            });
        },
        saveGate() {
            this.$refs.observer.validate().then(success => {
                if (!success) {
                    return;
                } else {
                    gateService.createGate(this.gate)
                        .then(data => {
                            this.gate = data.gateModel;
                            this.gate.deviceId = 0;
                            this.gate.stageId += 1;
                            this.makeToast(`Gate`, `Gate has been created.`);
                            this.getGate(this.gate.id);
                        }).catch((err) => {
                            this.makeToast(`Gate`, `Unable to create gate. Err: ${err}`, 'danger');
                        });
                }
            });
        },
        updateGate() {
            this.$refs.observer.validate().then(success => {
                if (!success) {
                    return;
                } else {
                    this.gate.gateIO = this.gateIO;
                    gateService.updateGate(this.gate)
                        .then(data => {
                            this.makeToast(`Gate`, `Gate values has been saved.`);
                        }).catch((err) => {
                            this.makeToast(`Gate`, `Unable to create gate. Err: ${err}`, 'danger');
                        });
                }
            });
        },
        fillView(data) {
            this.deviceList = data.devices;
            this.countryList = data.countries;
            this.timeZoneList = data.timeZones;

            this.customerOwnerList = data.customerOwners;
            this.gateInstallerList = data.customerGateInstallers;
            this.systemNameList = data.systemNames;
            this.gateIOList = data.gateIOs;
            this.gateTypeList = data.gateTypeList;
            this.countryList = data.countries;
            this.timeZoneList = data.timeZones;

        },
        nextIndex() {
            if (this.gate.stageId !== 2) {
                if (this.gate.stageId === 1 && this.gate.id === 0) {
                    this.saveGate();
                } else {
                    this.updateGate();
                    this.gate.stageId += 1;
                }
            }
        },
        previousIndex() {
            if (this.gate.stageId !== 1) {
                this.updateGate();
                this.gate.stageId -= 1;
            }
        },
        handleSkip(id) {
            router.push({ name: 'editGate', params: { gateId: parseInt(id) } });
            this.closeModal();
        },
        closeModal() {
            this.$emit('closeaddgate')
        },
        systemNameEventHandler(e) {
            this.errorMessage = "";
            const name = e.target.value;
            if (this.systemNameList.some(str => str.includes(name.toLowerCase()))) {
                this.errorMessage = "Namnet finns redan i listan!";
            }
        },
        switchToAddCustomer() {
            this.isAddCustomer = !this.isAddCustomer;
        },
        updateCustomerListAndSelect(customerId) {
            gateService.newGate().then(data => {
                this.fillView(data);
            });
            window.setTimeout(() => {
                this.gate.ownerCustomerId = customerId;
            }, 500);
        },
        onChangeGateModel: function () {
            if (this.selectedGateIO !== -1) {
                this.gateIO.inputSignals = this.gateIOList[this.selectedGateIO].inputSignals;
                this.gateIO.outputSignals = this.gateIOList[this.selectedGateIO].outputSignals;
                this.gateIO.type = this.gateIOList[this.selectedGateIO].type;
            }
        },
        getGate: function (gateid) {
            gateService.getGate(gateid).then(data => {
                this.gate = data.gate;
                if (this.gate.deviceId === null)
                    this.gate.deviceId = 0;
                this.gate.stageId += 1;
                this.fillView(data);
            });
        },
        newGateMetadata: function () {
            gateService.newGate().then(data => {
                this.fillView(data);
            });
        }
    },
    beforeMount() {
        if (this.gateId > 0) {
            this.getGate(this.gateId);
        } else {
            this.newGateMetadata();
        }
    }
}
</script>