<template>
    <div>
        <dmx-title :bc-items="bcItems">{{ $t('admin_dashboard_gates_title') }}</dmx-title>
        <b-row align-v="center" class="mb-1">
            <b-col>
                <div v-if="gateList.length">
                    {{ pagination.totalListCount }} gates found
                    <span class="ml-3">
                        Show: <b-link @click="setPerPage(10)" :style="((options.perPage == 10) ? 'font-weight: bold;' : ' ')">10</b-link> |
                        <b-link @click="setPerPage(20)" :style="((options.perPage == 20) ? 'font-weight: bold;' : ' ')">20</b-link> |
                        <b-link @click="setPerPage(100)" :style="((options.perPage == 100) ? 'font-weight: bold;' : ' ')">100</b-link>
                    </span>
                </div>
                <div v-else>
                    <b-icon icon="three-dots" animation="cylon" class="ml-3"></b-icon>
                </div>
            </b-col>
            <b-col>
                <b-input v-model="searchField"
                         @input="searchWithText()"
                         :loading="searchFieldIsLoading"
                         :placeholder="$t('common_search')">
                </b-input>
            </b-col>
            <b-col align="right">
                <b-button v-if="stateUser.userRole >= userRoles.DemexAdmin" variant="success" v-b-modal.add-gate-modal>
                    <b-icon icon="plus"></b-icon>
                    {{ $t('common_create') }}
                </b-button>
            </b-col>
        </b-row>
        <b-tabs lazy pills small content-class="mt-2">
            <b-tab title="List view">
                
                <b-table id="gates-table"
                         thead-class="dmx-thead-block"
                         tbody-tr-class="dmx-pointer-element"
                         :busy="loading"
                         outlined
                         striped
                         bordered
                         hover
                         :items="gateList"
                         :fields="headers"
                         @row-clicked="editGate">
                    <template #cell(stageId)="{ item }">
                        <span v-bind:class="{ 'setGreen' : item.stageId == 0,'setRed' : item.stageId > 0 }">
                            {{ item.stageId }}{{ item.stageId }}
                        </span>
                    </template>
                    <template #cell(status)="{ item }">
                        {{ statesFormatted(item.status) }}
                    </template>
                    <template #cell(createdOnUtc)="{ value }">
                        {{ utcFormatted(value) }}
                    </template>
                </b-table>

            </b-tab>
            <b-tab title="Map view" :disabled="listMapItemsDisabled">
                <div style="height: 600px;" class="mb-3">
                    <gate-map :gates="gateList" @marker-clicked="markerClicked"></gate-map>
                </div>
            </b-tab>
        </b-tabs>
        <div>
            <b-pagination align="center" v-model="options.currentPage"
                          :total-rows="pagination.totalListCount"
                          :per-page="options.perPage"
                          aria-controls="gates-table">
            </b-pagination>
        </div>
        <!-- Modal for gate-addnew -->
        <b-modal :no-close-on-backdrop="true" ref="addgate" hide-footer hide-header size="md" id="add-gate-modal" title="Add gate" body-class="p-0">
            <gate-addnew @closeaddgate="closeAddNewModal" :gateId="addEditGateId"></gate-addnew>
        </b-modal>
    </div>
</template>

<style scoped>
.setGreen{
    color:transparent;
    background-color: green;
}
.setRed{
    color:transparent;
    background-color: red;
}
</style>

<script>
    import { UserRoles } from '@/variables/variables.js'
    import { mapState } from 'vuex';
    import gateService from '@/services/gateService';
    import router from '@/router';
    import gateMapComponent from '@/components/gates/gate-map.vue';
    import gateAddnewComponent from '@/components/gates/gate-addnew.vue';

    export default {
        name: "GateList",
        components: {
            'gate-map': gateMapComponent,
            'gate-addnew': gateAddnewComponent,
        },
        data: () => ({
            addEditGateId: 0,
            search: '',
            loading: true,
            options: {
                perPage: 10,
                currentPage: 1
            },

            //flags
            searchFieldIsLoading: false,

            // values
            searchField: '',
            gateList: [],
            userRoles: UserRoles,

            // pagination
            pagination: {
                totalListCount: 0,
            },
            filterTableHeadName: '',
            timeoutId: 0,
            itemRowCountTracker: 0,
            bcItems: [
                {
                    text: 'gates',
                    href: '/gates',
                    active: true,
                },
            ]
        }),
        computed: {
            ...mapState({
                stateUser: store => store.authentication.user,
            }),
            headers() {
                return [
                    {
                        label: this.$t('common_id'),
                        align: 'start',
                        sortable: true,
                        key: 'id',
                    },
                    {
                        label: 'Stage',
                        align: 'start',
                        sortable: true,
                        key: 'stageId',
                    },
                    {
                        label: 'Ordernumber',
                        key: 'orderNumber',
                    },
                    {
                        label: 'Device',
                        key: 'deviceName',
                    },
                    {
                        label: 'System name',
                        align: 'start',
                        sortable: true,
                        key: 'systemName',
                    },
                    {
                        label: 'Display name',
                        align: 'start',
                        sortable: true,
                        key: 'displayName',
                    },
                    {
                        label: 'Company',
                        sortable: true,
                        key: 'company',
                    },
                    //{
                    //    label: 'Installer',
                    //    sortable: true,
                    //    key: 'installer',
                    //},
                    //{
                    //    label: 'Active alerts',
                    //    sortable: true,
                    //    key: 'activeAlerts',
                    //},
                    {
                        label: this.$t('common_status'),
                        sortable: true,
                        key: 'status',
                    },
                    //{
                    //    text: this.$t('admin_dashboard_device'),
                    //    sortable: true,
                    //    value: 'device',
                    //},
                    {
                        label: this.$t('common_created'),
                        sortable: true,
                        key: 'createdOnUtc',
                    }
                ]
            },
            listMapItemsDisabled() {
                let ret = true;
                for (let i in this.gateList) {
                    if (this.gateList[i].deviceLat && this.gateList[i].deviceLon) {
                        ret = false;
                        break;
                    }
                }
                return ret;
            }
        },
        methods: {
            statesFormatted(state) {
                let states = {
                    0: "Unknown",
                    10: "Closed",
                    20: "Open",
                    30: "Opening",
                    40: "Closing",
                }
                return states[state];
            },
            setPerPage(num) {
                this.options.currentPage = 1;
                this.options.perPage = num;
            },
            deleteAllGates() {
                gateService.deleteAll();
                this.getGates();
            },
            editGate(e) {
                if(parseInt(e.stageId) === 0){
                    // StageId is 0 continue to standard edit page
                    router.push({ name: 'editGate', params: { gateId: parseInt(e.id) } })
                }else {
                    // Open create modal and direct to the correct Stage in the creation process
                    this.$refs['addgate'].show();
                    this.addEditGateId = parseInt(e.id);
                }
            },
            markerClicked(id) {
                router.push({ name: 'editGate', params: { gateId: parseInt(id) } })
            },
            searchWithText() {
                this.searchFieldIsLoading = true;

                if (this.timeoutId > 0) {
                    clearTimeout(this.timeoutId);
                }
                this.timeoutId = window.setTimeout(() => {
                    this.getGates();
                }, 600);
            },
            filterByTableHeader(tableHeaderName) {
                this.filterTableHeadName = tableHeaderName;
                this.getGates();
            },
            getGates() {
                gateService.getGateList("FilterGateList", {
                    Count: this.options.perPage,
                    Gates: [],
                    //SortBy: this.options.sortBy[0],
                    SearchTerm: this.searchField,
                    //SortDesc: this.options.sortDesc[0],
                    Page: this.options.currentPage
                })
                .then((data) => {
                    this.gateList = data.items;
                    this.searchFieldIsLoading = false;
                    this.options.currentPage = data.page;
                    this.pagination.totalListCount = data.totalListCount;

                    this.loading = false;
                })
                .catch(function (error) {
                    alert(error);
                });
            },
            gateStatusChanged(data) {
                //console.log('SOCKET DATA Got state, gateId', data.state, data.gateId);
                for (let i in this.gateList) {
                    if (this.gateList[i].id == data.gateId) {
                        this.gateList[i].state = data;
                        break;
                    }
                }
            },
            closeAddNewModal() {
                this.addEditGateId = 0;
                this.$refs['addgate'].hide();
                window.setTimeout(() => {
                    this.getGates();
                }, 1000);
      }
        },
        watch: {
            options: {
                handler() {
                    this.getGates()
                },
                deep: true,
                immediate: true,
            },
        },
        mounted() {
            let self = this;
            this.$socket.on('GateStatusChange', (data) => {
                self.gateStatusChanged(data);
            });
        },
        beforeDestroy() {
            this.$socket.off('GateStatusChange', (err) => {});
        },
        
    }
</script>
